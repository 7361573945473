// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-faculty-js": () => import("./../../../src/pages/faculty.js" /* webpackChunkName: "component---src-pages-faculty-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mandatory-details-js": () => import("./../../../src/pages/mandatory-details.js" /* webpackChunkName: "component---src-pages-mandatory-details-js" */),
  "component---src-pages-marquee-js": () => import("./../../../src/pages/marquee.js" /* webpackChunkName: "component---src-pages-marquee-js" */),
  "component---src-pages-naac-js": () => import("./../../../src/pages/NAAC.js" /* webpackChunkName: "component---src-pages-naac-js" */),
  "component---src-pages-notice-js": () => import("./../../../src/pages/notice.js" /* webpackChunkName: "component---src-pages-notice-js" */),
  "component---src-pages-photo-gallery-js": () => import("./../../../src/pages/photo-gallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */),
  "component---src-pages-post-notice-js": () => import("./../../../src/pages/post-notice.js" /* webpackChunkName: "component---src-pages-post-notice-js" */),
  "component---src-pages-reading-material-js": () => import("./../../../src/pages/reading-material.js" /* webpackChunkName: "component---src-pages-reading-material-js" */),
  "component---src-pages-syllabus-js": () => import("./../../../src/pages/syllabus.js" /* webpackChunkName: "component---src-pages-syllabus-js" */)
}

